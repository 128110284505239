.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-container img {
  width: 100px;
  margin-bottom: 20px;
}

.login-container button {
  margin: 10px;
  padding: 10px 20px;
}

.button {
  padding: 10px 20px; /* Add some padding */
  font-size: 16px; /* Set font size */
  border: none; /* Remove borders */
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Change cursor to pointer when hovering */
  transition: background 0.3s; /* Smooth transition for hover effects */
}

.button {
  background-color: #0086fc; /* Set a background color */
  color: white; /* Set text color */
}

.button:hover {
  background-color: #007dfc; /* Darken the background color a bit when hovered */
}

.button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.button:active {
  transform: translateY(2px);
}

.button:focus {
  outline: none;
}
.feature-announcement-container {
  padding-top: 1.5vh; /* Adjust the padding-top to match the header's dynamic height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: "Arial", sans-serif; /* Arial font */
}

/* Content Styles */
/* Add a top padding to the main content to avoid hiding behind the fixed header */
.main-content {
  padding-top: 20px; /* Adjust this value based on your header's actual height */
  /* ... */
}

/* BlogPost styles */
.blog-post {
  margin-bottom: 20px;
}

.blog-date {
  font-style: italic;
  color: #777;
}

.announcement-content {
  max-width: 600px;
  padding-top: 20px; /* You can adjust this value as needed */
  padding-left: 10%; /* Percentage-based padding for responsive design */
  padding-right: 10%; /* Percentage-based padding for responsive design */
}

.announcement-content h1 {
  margin-bottom: 20px;
}

.announcement-content p {
  text-align: left;
  margin-bottom: 15px;
}

.rounded-logo {
  width: 100px;
  border-radius: 20px; /* Rounded corners like iOS apps */
  margin-bottom: 20px;
}
/* Reset default body margins and padding */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif; /* Consistent font */
}

.loading-message,
.error-message {
  /* ... existing styles ... */
  margin: 1em 0; /* Adjust top and bottom margins */
}

.App-header {
  background-color: rgba(
    40,
    44,
    52,
    0.5
  ); /* Increase transparency by changing the alpha value */
  backdrop-filter: blur(10px); /* Gaussian blur effect */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-sizing: border-box;
  height: 5vh; /* Making the header's height dynamic */
  padding: 1em; /* Adjust padding */
}

.App-logo-header {
  height: 30px;
  margin-right: 10px;
}

.App-title {
  color: white;
  font-size: 1.5em;
  margin: 0;
}

/* Style for the loading message */
.loading-message {
  color: #0086fc; /* Set the text color */
  font-size: 1.2em; /* Set font size */
  margin-top: 20px; /* Add top margin */
  font-weight: bold; /* Make the text bold */
}

/* Style for the error message */
.error-message {
  color: #ff6161; /* Set the text color to a light red for visibility */
  font-size: 1.2em; /* Set font size */
  margin-top: 20px; /* Add top margin */
  font-weight: bold; /* Make the text bold */
  border: 1px solid #ff6161; /* Add a border */
  padding: 10px; /* Add padding */
  border-radius: 5px; /* Rounded corners for the box */
  background-color: #ffecec; /* Light background color for contrast */
}

/* Content Styles */
.announcement-content,
.blog-posts-section {
  padding-top: calc(5vh + 1.5em); /* Adjust this value as needed */
  max-width: 1000px; /* Increased max-width for larger screens */
  margin: 1em auto;
  padding: 1em;
  padding-left: 5%; /* Percentage-based padding for responsive design */
  padding-right: 5%; /* Percentage-based padding for responsive design */
}

/* Blog Post Styles */
.blog-post {
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea; /* Add a subtle separator */
  padding-bottom: 20px; /* Space before the separator */
}

/* Media query for medium-sized screens (e.g., tablets) */
@media screen and (max-width: 768px) {
  .announcement-content,
  .blog-posts-section {
    padding-left: 5%;
    padding-top: calc(5vh + 22em); /* Adjust this value as needed */
    padding-right: 5%;
  }
}

/* Media query for smaller screens (e.g., mobile devices) */
@media screen and (max-width: 480px) {
  .announcement-content,
  .blog-posts-section {
    padding-left: 3%;
    padding-top: calc(5vh + 60em); /* Adjust this value as needed */
    padding-right: 3%;
  }
}
